import React from 'react';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import BabyChangingStationIcon from '@mui/icons-material/BabyChangingStation';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import ToysIcon from '@mui/icons-material/Toys';
import ListIcon from '@mui/icons-material/List';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { GiLipstick, GiSittingDog } from 'react-icons/gi';
import { TbRazor } from 'react-icons/tb';
import { FaToiletPaper, FaCat } from 'react-icons/fa';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import DinnerDiningIcon from '@mui/icons-material/DinnerDining';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DiscountIcon from '@mui/icons-material/Discount';
import SavingsIcon from '@mui/icons-material/Savings';

export default [
  {
    name: 'Subscribe & Save',
    showOnBanner: true,
    link: '/best-amazon-subscribe-save-deals',
    icon: <AutorenewIcon />
  },
  {
    name: 'Most Popular Deals',
    showOnBanner: true,
    shortName: 'Popular',
    link: '/p/best-selling-deals',
    icon: <EmojiEventsIcon />
  },
  {
    name: 'Deals for your Cat',

    link: '/p/best-deals-cats-amazon',
    icon: (
      <FaCat
        style={{
          fontSize: '24px'
        }}
      />
    )
  },
  {
    name: 'Deals for your Dog',
    link: '/p/best-deals-dogs-amazon',
    icon: (
      <GiSittingDog
        style={{
          fontSize: '24px'
        }}
      />
    )
  },
  {
    name: 'Promotions',
    showOnBanner: true,
    link: '/p/amazon-special-promotions',
    icon: <MoneyOffIcon />
  },
  {
    name: 'Coupon Deals',
    showOnBanner: true,
    link: '/amazon-coupon-deals',
    icon: <LocalOfferIcon />
  },
  {
    name: 'Diaper Deals',
    link: '/p/amazon-deals-amazon-deals-diapers',
    icon: <BabyChangingStationIcon />
  },
  {
    name: 'Weekly Shopping',
    showOnBanner: true,
    link: '/weekly',
    icon: <ListIcon />
  },
  {
    name: 'Toy Deals',
    showOnBanner: true,
    link: '/p/amazons-best-deals-toys-games',
    icon: <ToysIcon />
  },
  {
    name: 'BEST Grocery Deals',
    showOnBanner: true,
    shortName: 'Grocery',
    link: '/p/best-amazon-grocery-deals',
    icon: <DinnerDiningIcon />
  },
  {
    name: 'Back to School Deals',
    link: '/p/amazon-deals-back-to-school',
    icon: <SquareFootIcon />
  },
  {
    name: 'Amazon Deals on LEGO',
    showOnBanner: false,
    shortName: 'LEGO Deals',
    link: '/p/amazon-deals-on-lego',
    icon: <SmartToyIcon />
  },
  {
    name: 'Subscribe & Save Under $3',
    link: '/p/amazon-subscribe-save-under-three',
    icon: <CurrencyExchangeIcon />
  },
  {
    name: 'Razor & Shaving Deals',
    link: '/p/amazon-razor-shaving-deals',
    icon: (
      <TbRazor
        style={{
          fontSize: '24px'
        }}
      />
    )
  },
  {
    name: `BEST Deals on Beauty`,
    link: '/p/best-beauty-deals-on-amazon',
    icon: (
      <GiLipstick
        style={{
          fontSize: '24px'
        }}
      />
    )
  },
  {
    name: 'BEST Coupon Deals on Every Day Items',
    link: '/p/amazon-best-coupon-deals',
    icon: <DiscountIcon />
  },
  {
    name: 'Toilet Paper Deals',
    link: '/p/best-toilet-paper-deals-amazon',
    icon: (
      <FaToiletPaper
        style={{
          fontSize: '24px'
        }}
      />
    )
  },
  {
    name: 'Deals Under $1',
    showOnBanner: true,
    shortName: 'Under $1',
    link: '/p/amazon-deals-under-one-dollar',
    icon: <SavingsIcon />
  }
];
