import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Link as MuiLink } from '@mui/material';
import listLinks from '../../listLinks';

const DesktopMenuButtons = () => {
  const links = listLinks.filter((link) => link.showOnBanner);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <Box
      sx={{
        // "space-evenly" will place equal space on the left and right of each link
        // (You could also try "space-around" or "space-between")
        minHeight: '30px !important',
        maxWidth: {
          xs: '100%',
          sm: '100%',
          md: '100%',
          lg: '1360px',
          xl: '1360px'
        },
        display: {
          xs: 'none',
          sm: 'none',
          md: 'flex'
        },
        margin: '0 auto'
      }}
    >
      <Box
        sx={{
          // "space-evenly" will place equal space on the left and right of each link
          // (You could also try "space-around" or "space-between")
          justifyContent: 'space-between',
          alignItems: 'center',
          display: 'flex',
          margin: '0 10px',
          width: '100%'
        }}
      >
        {links.map((link, index) => (
          <MuiLink
            key={link.link}
            component={RouterLink}
            to={link.link}
            onClick={scrollToTop}
            sx={{
              // display: 'inline-flex',
              alignItems: 'center',
              color: 'white',
              textDecoration: 'none',
              // If you want a hover color change, for example:
              '&:hover': {
                color: 'lightgray'
              },
              display: {
                xl: 'inline-flex',
                lg: index > 6 ? 'none' : 'inline-flex',
                md: index > 5 ? 'none' : 'inline-flex'
              }
            }}
          >
            {/* Icon to the left */}
            <Box
              component="span"
              sx={{
                mr: 1,
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {link.icon}
            </Box>
            {link.shortName || link.name}
          </MuiLink>
        ))}
      </Box>
    </Box>
  );
};

export default DesktopMenuButtons;
