import colors from '@utils/colors';

export const lightIconStyles = {
  color: '#fff'
};

export const muiSelectedStyles = {
  '&.Mui-selected': {
    backgroundColor: `${colors.jdbPurple} !important`,
    color: 'white!important',
    '&:hover': {
      backgroundColor: `${colors.jdbPurple} !important`,
      color: 'white!important'
    }
  },
  '&.Mui-selected:focus-visible': {
    backgroundColor: `${colors.jdbPurple} !important`,
    color: 'white!important'
  }
};

export const borderColor = {
  borderStyle: 'solid',
  borderColor: 'rgba(0, 0, 0, .12)'
};

export const borderTopStyle = {
  ...borderColor,
  borderTopWidth: 'thin'
};

export const borderBottomStyle = {
  ...borderColor,
  borderBottomWidth: 'thin'
};
